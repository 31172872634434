// slice name

export const faqSliceName = 'faq'
export const categorySliceName = 'category'
export const productCategorySliceName = 'productCategory'
export const industrySliceName = 'industry'
export const citySliceName = 'city'

export const pageSize = 10

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
