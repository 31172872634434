import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidebarShow: true,
}

export const counterSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    set: (state) => {
      state.sidebarShow = !state.sidebarShow
    },
  },
})

// Action creators are generated for each case reducer function
export const { set } = counterSlice.actions

// select sidebarShow
export const selectSideBar = (state) => state.sidebar.sidebarShow

export default counterSlice.reducer
