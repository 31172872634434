import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isAuth: false,
  jwt: '',
  isLoading: true,
}

export const counterSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    successAuth: (state, { payload }) => {
      const { jwt, isLoading } = payload
      return {
        isAuth: jwt ? true : false,
        jwt: jwt ? jwt : null,
        isLoading,
      }
    },
    logOut: (state) => {
      return {
        isAuth: false,
        jwt: null,
      }
    },
  },
})

export const { successAuth, logOut } = counterSlice.actions

// selectors
export const selectIsAuth = (state) => state.auth

export default counterSlice.reducer
