import { createSlice } from '@reduxjs/toolkit'
import { industrySliceName } from '../utils/constant'
import { setAddNewData, setRemoveData, setUpdateData } from '../utils'

const initialState = {
  industries: [],
  isSet: false,
  filter: {},
}

const industrySlice = createSlice({
  name: industrySliceName,
  initialState,
  reducers: {
    setIndustries: (state, { payload }) => {
      const { industries } = payload
      state.industries = industries
      state.isSet = true

      return state
    },
    addNewIndustry: (state, { payload }) => {
      const { industry } = payload

      state.industries = setAddNewData(state.industries, industry)
      return state
    },
    removeIndustry: (state, { payload }) => {
      const { industryId } = payload

      state.industries = setRemoveData(state.industries, industryId)
      return state
    },
    updateIndustry: (state, { payload }) => {
      const { industryInfo } = payload

      state.industries = setUpdateData(state.industries, industryInfo)
      return state
    },
    filterIndustries: (state, { payload }) => {
      const { category } = payload
      state.filter.category = category
      state.isSet = false

      return state
    },
  },
})

export const { setIndustries, addNewIndustry, removeIndustry, updateIndustry, filterIndustries } =
  industrySlice.actions

// selectors
export const selectIndustries = (state) => state.industry.industries
export const selectIndustryIsSet = (state) => state.industry.isSet
export const selectIndustryFilter = (state) => state.industry.filter

export default industrySlice.reducer
