import { useState } from 'react'
import { toast } from 'react-toastify'

export default (apiFunc, loadingInit = false, defaltData = null) => {
  const [data, setData] = useState(defaltData)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(loadingInit)

  const request = async (...args) => {
    setLoading(true)
    try {
      const result = await apiFunc(...args)
      setData(result)
    } catch (err) {
      setError(err.message ? err.message : 'Unexpected Error!')
      if (data) setData(null)
      toast.error(err.message)
    } finally {
      setLoading(false)
    }
  }

  const updateData = (data) => {
    setData(data)
  }

  return {
    data,
    error,
    loading,
    request,
    updateData,
  }
}
