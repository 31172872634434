import React from 'react'

const Loader = ({ className }) => {
  return (
    <div className={`loader ${className}`}>
      <div className="loader_ajax_small"></div>
    </div>
  )
}

export default Loader
