import axios from 'axios'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logOut } from 'src/store/authSlice'

const apiClient = axios.create({
  // Later read this URL from an environment variable
  // baseURL: 'http://18.158.73.74:3000/api/v1',
  baseURL: 'https://apk.anudar.app/api/v1',
})

// Function to handle logout
export const logOutHandler = (dispatch, navigate) => {
  localStorage.clear()
  dispatch(logOut())
  navigate('/login')
}

// Function to set up interceptors
export const setupInterceptors = (dispatch, navigate) => {
  apiClient.interceptors.response.use(
    (response) => response, // Pass through successful responses
    (error) => {
      if (error?.response?.data?.statusCode === 401) {
        logOutHandler(dispatch, navigate)
      }
      return Promise.reject(error)
    },
  )
}

export const headers = (arg) => {
  return {
    Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    ...(arg && { ...arg }),
  }
}

export const post = async (arg) => {
  try {
    const {
      data: { data },
    } = await apiClient({
      method: 'Post',
      ...(arg && { ...arg }),
    })

    return data
  } catch (e) {
    throw new Error(e?.response?.data?.message || 'Something Went Wrong!')
  }
}

export const get = async (arg) => {
  try {
    const {
      data: { data },
    } = await apiClient({
      method: 'Get',
      ...(arg && { ...arg }),
    })

    return data
  } catch (e) {
    throw new Error(e?.response?.data?.message || 'Something Went Wrong!')
  }
}

export const patch = async (arg) => {
  try {
    const {
      data: { data },
    } = await apiClient({
      method: 'Patch',
      ...(arg && { ...arg }),
    })
    return data
  } catch (e) {
    throw new Error(e?.response?.data?.message || 'Something Went Wrong!')
  }
}

export const del = async (arg) => {
  try {
    const {
      data: { data },
    } = await apiClient({
      method: 'Delete',
      ...(arg && { ...arg }),
    })
    return data
  } catch (e) {
    throw new Error(e?.response?.data?.message || 'Something Went Wrong!')
  }
}
