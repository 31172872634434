export const setAddNewData = (list, newData) => {
  return [...list, newData]
}

export const setRemoveData = (list, _id) => {
  return list.filter((it) => it._id != _id)
}

export const setUpdateData = (list, updatedData) => {
  let dataList = [...list]
  const index = dataList.findIndex((it) => it._id === updatedData._id)
  if (index >= 0) {
    dataList[index] = { ...updatedData }
  }
  return dataList
}

export const bodyHandler = (e, objBody, setObj) => {
  let body = { ...objBody }
  body[e.target.name] = e.target.value
  setObj(body)
}

export const bodyImageHandler = (e, objBody, setObj) => {
  let body = { ...objBody }
  body[e.target.name] = e.target.files[0]
  setObj(body)
}
