import { createSlice } from '@reduxjs/toolkit'
import { citySliceName } from '../utils/constant'
import { setAddNewData, setRemoveData, setUpdateData } from '../utils'

const initialState = {
  cities: [],
  isSet: false,
}

const citySlice = createSlice({
  name: citySliceName,
  initialState,
  reducers: {
    setCities: (state, { payload }) => {
      const { cities } = payload
      state.cities = cities
      state.isSet = true

      return state
    },
    addNewCity: (state, { payload }) => {
      const { city } = payload

      state.cities = setAddNewData(state.cities, city)
      return state
    },
    removeCity: (state, { payload }) => {
      const { cityId } = payload

      state.cities = setRemoveData(state.cities, cityId)
      return state
    },
    updateCity: (state, { payload }) => {
      const { cityInfo } = payload

      state.cities = setUpdateData(state.cities, cityInfo)
      return state
    },
  },
})

export const { setCities, addNewCity, removeCity, updateCity } = citySlice.actions

// selectors
export const selectCities = (state) => state.city.cities
export const selectCityIsSet = (state) => state.city.isSet

export default citySlice.reducer
