import { configureStore } from '@reduxjs/toolkit'
import authSlice from './authSlice'
import sidebarSlice from './sidebarSlice'
import faqSlice from './faqSlice'
import categorySlice from './categorySlice'
import productCategorySlice from './productCategorySlice'
import industrySlice from './industrySlice'
import citySlice from './citySlice'

export const store = configureStore({
  reducer: {
    sidebar: sidebarSlice,
    auth: authSlice,
    faq: faqSlice,
    category: categorySlice,
    productCategory: productCategorySlice,
    industry: industrySlice,
    city: citySlice,
  },
})

export default store
