import { createSlice } from '@reduxjs/toolkit'
import { faqSliceName } from '../utils/constant'
import { setAddNewData, setRemoveData, setUpdateData } from '../utils'

const initialState = {
  faqs: [],
  isSet: false,
}

const faqSlice = createSlice({
  name: faqSliceName,
  initialState,
  reducers: {
    setFaqs: (state, { payload }) => {
      const { faqs } = payload
      state.faqs = faqs
      state.isSet = true

      return state
    },
    addNewFaq: (state, { payload }) => {
      const { faq } = payload

      state.faqs = setAddNewData(state.faqs, faq)
      return state
    },
    removeFaq: (state, { payload }) => {
      const { faqId } = payload

      state.faqs = setRemoveData(state.faqs, faqId)
      return state
    },
    updateFaq: (state, { payload }) => {
      const { faqInfo } = payload

      state.faqs = setUpdateData(state.faqs, faqInfo)
      return state
    },
  },
})

export const { setFaqs, addNewFaq, removeFaq, updateFaq } = faqSlice.actions

// selectors
export const selectFaqs = (state) => state.faq.faqs
export const selectFaqIsSet = (state) => state.faq.isSet

export default faqSlice.reducer
