import { createSlice } from '@reduxjs/toolkit'
import { productCategorySliceName } from '../utils/constant'
import { setAddNewData, setRemoveData, setUpdateData } from '../utils'

const initialState = {
  productCategories: [],
  isSet: false,
}

const productCategorySlice = createSlice({
  name: productCategorySliceName,
  initialState,
  reducers: {
    setProductCategories: (state, { payload }) => {
      const { productCategories } = payload
      state.productCategories = productCategories
      state.isSet = true

      return state
    },
    addNewProductCategory: (state, { payload }) => {
      const { productCategory } = payload

      state.productCategories = setAddNewData(state.productCategories, productCategory)
      return state
    },
    removeProductCategory: (state, { payload }) => {
      const { productCategoryId } = payload

      state.productCategories = setRemoveData(state.productCategories, productCategoryId)
      return state
    },
    updateProductCategory: (state, { payload }) => {
      const { productCategoryInfo } = payload

      state.productCategories = setUpdateData(state.productCategories, productCategoryInfo)
      return state
    },
  },
})

export const {
  setProductCategories,
  addNewProductCategory,
  removeProductCategory,
  updateProductCategory,
} = productCategorySlice.actions

// selectors
export const selectProductCategories = (state) => state.productCategory.productCategories
export const selectProductCategoryIsSet = (state) => state.productCategory.isSet

export default productCategorySlice.reducer
