import { createSlice } from '@reduxjs/toolkit'
import { categorySliceName } from '../utils/constant'
import { setAddNewData, setRemoveData, setUpdateData } from '../utils'

const initialState = {
  categories: [],
  isSet: false,
}

const categorySlice = createSlice({
  name: categorySliceName,
  initialState,
  reducers: {
    setCategories: (state, { payload }) => {
      const { categories } = payload
      state.categories = categories
      state.isSet = true

      return state
    },
    addNewCategory: (state, { payload }) => {
      const { category } = payload

      state.categories = setAddNewData(state.categories, category)
      return state
    },
    removeCategory: (state, { payload }) => {
      const { categoryId } = payload

      state.categories = setRemoveData(state.categories, categoryId)
      return state
    },
    updateCategory: (state, { payload }) => {
      const { categoryInfo } = payload

      state.categories = setUpdateData(state.categories, categoryInfo)
      return state
    },
  },
})

export const { setCategories, addNewCategory, removeCategory, updateCategory } =
  categorySlice.actions

// selectors
export const selectCategories = (state) => state.category.categories
export const selectCategoryIsSet = (state) => state.category.isSet

export default categorySlice.reducer
