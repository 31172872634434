import { del, get, patch, post } from '../services/client'

class AuthService {
  static async signIn(data) {
    return await post({ url: 'auth/signIn', data })
  }

  static async adminAuthorize() {
    await get({ url: 'auth/admin/authorize' })
    return true
  }

  static async forgotPass(data) {
    return await post({ url: 'auth/forgotPassword/otp-request', data })
  }

  static async verifyOtp(data) {
    return await post({ url: 'auth/forgotPassword/verify-otp', data })
  }

  static async resetPassword(data) {
    return await post({ url: 'auth/set-new-password', data })
  }
}

export default AuthService
